.column {
    text-align: center;
}

.button.is-large {
    font-size: 2.2rem;
}

.scrollable{
    overflow-y: auto;
}

.narrow-column{
    padding: ""
}

.bordered-cell{
    border-left-width: 2px !important;
    border-right-width: 2px !important;
}

.is-narrower td, th {
    vertical-align: middle !important;
    padding: 0.1em !important;
}
